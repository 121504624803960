<template>
  <transition enter-active-class="animated fadeInDown" leave-active-class="animated fadeOutUp">
    <div class="location-dropdown" v-show="show">
      <h3>Find a Location</h3>
      <img class="close cursor-pointer" src="~/assets/img/icons/close.svg" alt="Close Icon" @click="close"/>
      <form class="flex" v-on:submit.prevent="searchLocations">
        <input type="text" placeholder="City, State or Zip Code" v-model="search.zipcode" />
        <button class="submit"><img src="~/assets/img/icons/caret-right.svg" alt="Return Icon"/></button>
      </form>
      <div class="location-dropdown-results">
        <div v-if="finding">
          <loader />
        </div>
        <div v-else-if="locations.length > 0">
          <div v-for="loc in locations" :key="loc.index" class="location-dropdown-result">
            <h4>{{loc.centerName}}</h4>
            <p>{{loc.address}}, {{loc.city}}, {{loc.state}} {{loc.zipcode}}</p>
            <!-- Example for NCO Additions Below -->
            <p v-if="loc.centerName === 'Montclair'">Coming Soon!</p>
            <p v-else >{{ formatTime(loc) }}</p>
            <div class="flex">
              <button v-on:click.prevent="setLocation(loc)" :class="locationId == loc.centerId ? 'pink' : ''">Select{{ locationId == loc.centerId ? 'ed' : ''}}</button>
              <nuxt-link class="button" :to="pageLink(loc)">More Info</nuxt-link>
            </div>
          </div>
        </div>
        <div v-else-if="searched">NO LOCATIONS NEARBY</div>
        <div v-else-if="error">Enable location services or search above.</div>
      </div>
    </div>
  </transition>
</template>

<script>
import moment from 'moment'
import Loader from '../common/Loader'
const states = require('~/assets/config/states.json')

export default {
  components: {
    Loader
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searching: false,
      searched: false,
      search: {
        zipcode: null
      }
    }
  },
  computed: {
    locationId() {
      return this.$store.state.location ? this.$store.state.location.centerId : null
    },
    locations() {
      return this.$store.state.locations
    },
    performSearch() {
      return this.searching
    },
    finding() {
      return this.$store.state.finding
    }
  },
  mounted() {
    if(this.$store.state.zipcode) {
      this.search.zipcode = this.$store.state.zipcode
      // if(!this.$store.state.locations) {
      //   this.searchLocations()
      // }
    }
  },
  methods: {
    close() {
      this.$parent.toggleLocationDropdown()
    },
    formatTime(loc) {
      let hours = this.$store.state.centerData[loc.centerId] && this.$store.state.centerData[loc.centerId].hours ? this.$store.state.centerData[loc.centerId].hours[moment().format('dddd').toLowerCase()] : null
      if(hours) {
        if(hours.isClosedWholeDay == true) {
          return 'Closed Today'
        } else {
          let open = hours.openIntervals[0].start.split(':')
          let closed = hours.openIntervals[hours.openIntervals.length - 1].end.split(':')
          return `Open Today: ${moment().hour(open[0]).minute(open[1]).format('h a')} - ${moment().hour(closed[0]).minute(closed[1]).format('h a')}`
        }
      } else {
        return ''
      }
    },
    pageLink(loc) {
      // NCO Redirect Code Example Below, lines 105-107

      if (loc.centerName === 'Montclair') {
        return `/montclair`
      }

      return `/locations/${this.$store.state.centerData[loc.centerId].path}`
    },
    setLocation(loc) {
      loc.hours = this.$store.state.centerData[loc.centerId].hours
      loc.venues = this.$store.state.centerData[loc.centerId].venues
      this.$store.commit('setLocation', loc)
      this.$parent.toggleLocationDropdown()
    },
    async searchLocations() {
      if(this.search.zipcode) {
        this.$store.commit('setZipcode', this.search.zipcode)
        this.findLocations(this.search)
      }
    },
    async findLocations(search) {
      if(!this.finding) {
        this.$store.commit('finding', true)
        let locations = await this.$api.getLocations(this.search)
        this.$store.commit('setLocations', locations)
        this.searched = true
        this.$store.commit('finding', false)
      }
    }
  }
}
</script>

<style lang="scss">

</style>