import Vue from 'vue'

const globalComponents = {
  ActivitiesFormMain: () => import('../../components/forms/ActivitiesFormMain.vue' /* webpackChunkName: "components/forms/ActivitiesFormMain" */).then(c => c.default || c),
  Counter: () => import('../../components/forms/Counter.vue' /* webpackChunkName: "components/forms/Counter" */).then(c => c.default || c),
  CounterWithDetails: () => import('../../components/forms/CounterWithDetails.vue' /* webpackChunkName: "components/forms/CounterWithDetails" */).then(c => c.default || c),
  CountriesSelect: () => import('../../components/forms/CountriesSelect.vue' /* webpackChunkName: "components/forms/CountriesSelect" */).then(c => c.default || c),
  DateField: () => import('../../components/forms/DateField.vue' /* webpackChunkName: "components/forms/DateField" */).then(c => c.default || c),
  DatePick: () => import('../../components/forms/DatePick.vue' /* webpackChunkName: "components/forms/DatePick" */).then(c => c.default || c),
  FormMain: () => import('../../components/forms/FormMain.vue' /* webpackChunkName: "components/forms/FormMain" */).then(c => c.default || c),
  GroupField: () => import('../../components/forms/GroupField.vue' /* webpackChunkName: "components/forms/GroupField" */).then(c => c.default || c),
  InvestorFranchiseForm: () => import('../../components/forms/InvestorFranchiseForm.vue' /* webpackChunkName: "components/forms/InvestorFranchiseForm" */).then(c => c.default || c),
  MobileSelect: () => import('../../components/forms/MobileSelect.vue' /* webpackChunkName: "components/forms/MobileSelect" */).then(c => c.default || c),
  Options: () => import('../../components/forms/Options.vue' /* webpackChunkName: "components/forms/Options" */).then(c => c.default || c),
  OptionsPurple: () => import('../../components/forms/OptionsPurple.vue' /* webpackChunkName: "components/forms/OptionsPurple" */).then(c => c.default || c),
  Select: () => import('../../components/forms/Select.vue' /* webpackChunkName: "components/forms/Select" */).then(c => c.default || c)
}

for (const name in globalComponents) {
  Vue.component(name, globalComponents[name])
}
